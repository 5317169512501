/*  ==========================================================================
    POPUP
    ========================================================================== */

$module-clr__popup--text: $clr__typo--primary;
$module-clr__popup--accent: $clr__ci--primary;

/*
 * MODULE
 */

.bn-popup {
  display: none;
  align-items: center;
  position: fixed;
  z-index: 750;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-color: rgba($clr__black, 0.3);
  backdrop-filter: blur(3em);

  &.JS-active {
    display: flex;
  }

  &__close {
    position: absolute;
    right: 1.5em;
    top: 1.5em;
    cursor: pointer;
    transition: transform 0.5s;
    .bn-icon {
      font-size: 2em;
      pointer-events: none;
    }
    &:hover {
      transform: rotate(90deg);
    }
  }

  &__wrap {
    max-width: 90em;
    margin: 0 auto;
    padding: 9em 12.5em 6em 12.5em;
    background-color: $clr__white;
    max-height: 90vh;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__headline {
    margin-bottom: 4em;
    text-align: center;
    span {
      font-size: 2.2em;
      font-weight: $typo__fw--bold;
      text-transform: uppercase;
    }
  }

  &__text {
    @include typo--rte($module-clr__popup--text,$module-clr__popup--accent);
    text-align: center;
    a {
      text-decoration: none;
      color: $clr__bn-red !important;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__button {
    @include button--default();
    margin: 5.5em auto 0 auto;
  }

}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__tablet} {
  .bn-popup {
    &__headline {
      margin-bottom: 1.5em;
    }

    &__wrap {
      max-width: 100%;
      margin: 1em;
      padding: 4em 2em;
    }

    &__button {
      margin: 2em auto 0 auto;
    }
  }
}