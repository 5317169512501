/*  ==========================================================================
    ROOMS LIST VIEW V3
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__rooms-list-view-v3--bg-primary: $clr__ci--primary;
$module-clr__rooms-list-view-v3--bg-secondary: $clr__ci--secondary;
$module-clr__rooms-list-view-v3--typo-primary: $clr__typo--secondary;
$module-clr__rooms-list-view-v3--typo-secondary: $clr__typo--secondary;

/*
 * MODULE
 */
.eh-detail-link {
  text-decoration: none !important;

}
.bn-rooms-list-view-v3 {
  .bn-rooms-list-view-v3__tabs {
    padding-bottom: 70px;
    text-align: center;
    > div {
      display: inline-block;
      padding: 10px;
    }
    .bn-button {
      @include button--reset();
      @include button--default-inverted();
      &.bn-button--act {
        background-color: $clr__ci--primary !important;
        border-color: $clr__ci--primary !important;
        color: $clr__typo--secondary !important;
      }
    }
  }
  .bn-rooms-list-view-v3__holder {
    margin: 0 auto;
    max-width: 1024px;
    .bn-room-box-v3 {
      color: $clr__ci--secondary;
      margin-top: 80px;
      a {
        color: $clr__ci--secondary;
        &:visited {
          color: $clr__typo--primary;
        }
        &:hover {
          color: $clr__typo--primary;
        }
      }
      > div {
        display: flex;
        flex-wrap: wrap;
      }
      .bn-room-box-v3__image {
        @include clearfix();
        display: block;
        margin-top: 40px;
        order: 2;
        padding-right: 60px;
        position: relative;
        width: calc(100% - 300px);
        .picture {
          display: block;
          float: right;
          overflow: hidden;
          width: calc(100% + 150px);
          img {
            @include img--responsive();
          }
        }
        .bn-room-box-v3__price {
          background-color: $module-clr__rooms-list-view-v3--bg-primary;
          display: inline-block;
          height: 120px;
          min-width: 120px;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          z-index: 5;
          border-radius: 15px;
          > div {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            padding: 10px;
            position: relative;
            text-align: center;
          }
          .bn-room-box-v3__amount-addition {
            font-size: 1.4em;
          }
          .bn-icon {
            font-size: 1.6em;
            margin-right: 5px;
          }
          .bn-room-box-v3__amount {
            font-size: 2.0em;
          }
        }
      }
      .bn-room-box-v3__content {
        background-color: $module-clr__rooms-list-view-v3--bg-primary;
        margin-bottom: 40px;
        order: 1;
        padding: 40px 20px 130px 20px;
        position: relative;
        text-align: center;
        width: 300px;
        z-index: 5;
        .bn-room-box-v3__name {
          /*@include typo--subheadline($module-clr__rooms-list-view-v3--typo-primary);*/
          color: $clr__ci--secondary;
          font-family: $typo__ff--Abel;
          font-size: 30px;
          font-weight: $typo__fw--regular;
          text-transform: uppercase;
          text-decoration: none !important;
        }
        .bn-room-box-v3__stats {
          font-size: 1.6em;
          margin-top: 20px;
        }
        .bn-room-box-v3__button {
          bottom: 40px;
          left: 0;
          padding: 0 20px;
          position: absolute;
          width: 100%;
          z-index: 5;
          .bn-button {
            @include button--custom($module-clr__rooms-list-view-v3--typo-primary,$module-clr__rooms-list-view-v3--typo-primary,$clr__ci--secondary,transparent,$module-clr__rooms-list-view-v3--typo-primary,$module-clr__rooms-list-view-v3--typo-primary);
            min-width: 0 !important;
            width: 100% !important;
          }
        }
      }
      // remove margin top on first box
      &:first-child {
        margin-top: 0;
      }
      // recolor every second box
      &:nth-child(even) {
        a {
          color: $module-clr__rooms-list-view-v3--typo-secondary;
          &:visited {
            color: $module-clr__rooms-list-view-v3--typo-secondary;
          }
          &:hover {
            color: $module-clr__rooms-list-view-v3--typo-secondary;
          }
        }
        .bn-room-box-v3__image {
          .bn-room-box-v3__price {
            background-color: $module-clr__rooms-list-view-v3--bg-secondary;
          }
        }
        .bn-room-box-v3__content {
          color: $clr__white;
          background-color: $module-clr__rooms-list-view-v3--bg-secondary;
          .bn-room-box-v3__name {
            @include typo--subheadline($module-clr__rooms-list-view-v3--typo-secondary);
            color: $clr__white;
            font-family: $typo__ff--Abel;
            font-size: 30px;
            font-weight: $typo__fw--regular;
          }
          .bn-room-box-v3__button {
            .bn-button {
              @include button--custom($module-clr__rooms-list-view-v3--typo-secondary,$module-clr__rooms-list-view-v3--typo-secondary,$module-clr__rooms-list-view-v3--bg-secondary,transparent,$module-clr__rooms-list-view-v3--typo-secondary,$module-clr__rooms-list-view-v3--typo-secondary);
            }
          }
        }
      }
    }
  }
  &.bn-rooms-list-view-v3--filtered {
    .bn-rooms-list-view-v3__holder {
      .bn-room-box-v3 {
        display: none;
        &.bn-room-box-v3--act {
          display: block;
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  .bn-rooms-list-view-v3 {
    .bn-rooms-list-view-v3__holder {
      .bn-room-box-v3 {
        margin-top: 40px;
        .bn-room-box-v3__image {
          padding-right: 0;
          .bn-room-box-v3__price {
            bottom: 20px;
            background-color: $module-clr__rooms-list-view-v3--bg-primary;
            right: 20px;
            top: auto;
            transform: translateY(0);
            border-radius:0px;
          }
        }
        .bn-room-box-v3__content {
          padding-top: 40px;
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-rooms-list-view-v3 {
    .bn-rooms-list-view-v3__tabs {
      padding-bottom: 30px;
      > div {
        display: block;
      }
    }
    .bn-rooms-list-view-v3__holder {
      .bn-room-box-v3 {
        margin-top: 20px;
        > div {
          display: block;
        }
        .bn-room-box-v3__image {
          margin-top: 0;
          width: 100%;
          .picture {
            float: none;
            width: 100%;
          }
          .bn-room-box-v3__price {
            border-bottom: 1px solid $module-clr__rooms-list-view-v3--typo-primary;
            bottom: auto;
            display: block;
            height: auto;
            min-width: 0;
            position: relative;
            right: auto;
            border-radius: 0px;
            br {
              display: none;
            }
            .bn-room-box-v3__amount-addition {
              margin: 0 5px;
            }
            .bn-room-box-v3__amount {
              font-weight: $typo__fw--bold;
            }
          }
        }
        .bn-room-box-v3__content {
          margin-bottom: 0;
          padding-bottom: 90px;
          padding-top: 20px;
          width: 100%;
          .bn-room-box-v3__button {
            bottom: 20px;
          }
        }
        // recolor every second box
        &:nth-child(even) {
          .bn-room-box-v3__image {
            .bn-room-box-v3__price {
              border-bottom-color: $module-clr__rooms-list-view-v3--typo-secondary;
            }
          }
        }
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-rooms-list-view-v3 {
    .bn-rooms-list-view-v3__tabs {
      padding-bottom: 15px;
      > div {
        padding: 5px;
      }
    }
  }
}