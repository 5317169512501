/*  ==========================================================================
    QUICK REQUEST V1
    ========================================================================== */

@mixin quick-request--v1() {
  background-color: $clr__purple;
  bottom: 0;
  height: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: $unit__zi--quick-request;
  > .bn-grid__row {
    height: 100%;
  }
  .bn-quick-request__title,
  .bn-quick-request__form {
    align-items: center;
    display: flex;
  }
  .bn-quick-request__title {
    color: $clr__typo--primary;
    display: none;
    font-size: 2.0em;
    max-width: 15%;
    text-transform: uppercase;
    > div {
      width: 100%;
    }
  }
  .bn-quick-request__form {
    @include quick-request--horizontal();
  }
  .select_hotels {
    position: absolute;
    top: -4.5em;
    select {
      font-size: 2em;
      padding: .5em 1em;
      border: 1px solid $clr__purple;
      outline: none;
      color: $clr__purple;
    }
  }
  &.bn-quick-request--small {
    width: calc(100% - #{$unit__wdt--header-v3-sidebar-desktop-large});
    .bn-quick-request__title {
      display: none;
    }
  }
  &.bn-quick-request--visible {
    height: $unit__hgt--quick-request-v1;
    overflow: visible;

  }

  .bn-iconBtn {
    border-radius: 0px !important;
  }



  /*
   * RESPONSIVE BEHAVIOR
   */

  // desktop medium
  @media #{$mq__desktop--medium} {
    &.bn-quick-request--small {
      width: calc(100% - #{$unit__wdt--header-v3-sidebar});
    }
  }
  // laptop
  @media #{$mq__laptop} {
    .bn-quick-request__title {
      display: none;
    }
  }
  // tablet
  @media #{$mq__tablet} {
    display: none;
  }
}

.bn-f-top__boxes {
  .bn-form__button {
    background-color: transparent !important;
    border-color: $clr__white !important;
    color: white !important;
    font-family: Open;
    border-width: 2px;
    font-size: 16px;
    &:hover {
      background-color: $clr__cta--primary !important;
      border-color: $clr__cta--primary !important;
      color: $clr__white !important;
    }
  }
}

.bn-form-quickrequest-container {
  .bn-form__button {
    background-color: $clr__cta--primary !important;
    border-color: $clr__cta--primary !important;
    color: $clr__white !important;
    font-family: Open;
    font-weight: bold;
    border-width: 2px;
    font-size: 16px;
    &:hover {
      background-color: transparent !important;
      border-color: white !important;
      color: $clr__white !important;
    }
  }
}
