/*  ==========================================================================
    LINKBOX
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__linkboxes--color: $clr__typo--secondary;
$module-clr__linkboxes--bg: $clr__purple;

/*
 * MODULE
 */

.bn-linkbox {
  .bn {
    &__container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
    }
    &__item {
      position: relative;
      max-width: 380em;
      margin: 0 2px;
      text-align: center;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 1px;
        transition: .3s;
        background: $clr__bn-red;
        opacity: .6;
      }
      &:hover {
        &:after {
          background: transparent;
        }
        .bn {
          &__headlines {
            opacity: 0;
          }
        }
        img {
          filter: opacity(1);
        }
      }
    }
    &__headlines {
      position: absolute;
      left: 3em;
      right: 3em;
      top: 3em;
      bottom: 3em;
      color: $clr__white;
      z-index: 1;
      opacity: 1;
      transition: .3s;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-transform: uppercase;
      .DNA-header {
        line-height: 1.2;
        font-weight: 500;
        letter-spacing: 1.4px;
        > * {
          font-size: 2em;
        }
      }
      .DNA-subheader {
        line-height: 1.2;
        letter-spacing: 1.05px;
        > * {
          font-size:1.52em;
        }
      }
    }
  }

  img {
    filter: opacity(.7);
    transition: .3s;
    width: 100%;
    height: auto;
  }

  // --- properties ---
  &:hover {
    .bn {

    }
  }

  /* RESPONSIVE
   * --------------------------------------------------------------------------- */

  @media #{$mq__phablet} {

    // --- children ---
    .bn {
      &__container {
        flex-wrap: wrap;
      }
      &__item {
        margin-bottom: 3.5em;
      }
    }
  }
}
